
p {
font-size: medium;
}

.footer {
    bottom: 0; 
    /* background: #000 !important; */
    width: 100%;
}

.footer-logo{
    height: 42px;
    margin-left: -10px;
    margin-bottom:12px
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: DomaineSansText-Light;
    background: #000;
    padding: 30px 50px;
}
.mobile-footer-links {
    display: none;
}

.ant-layout-footer {
    padding: 0;
    margin: 0;
}

.title {
    color: #fff;
    font-family: DomaineSansText-Regular;
    margin-bottom: 24px;
}

/* .fLine {
    border: 0px;
    height: 1px;
    background-color:#32353C;
    width: 100%;
} */

.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.div-contact {
    flex: 1;
    color: #b5b5b5;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.help {
    display: block;
}

.mobile-help {
    display: none;
}

.help-links {
    display: flex;
    justify-content: space-between;
    width: 400px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
    background: #FFF;
}

.payment-cards-container{
    margin-top:10px;
}
.mobile-payment-cards-container{
    display: none;
}

.payment {
display: flex;
justify-content: space-between;
margin-bottom: 40px;
width: 240px;
}

.payment-icon {
    width: 35px;
}

.fText {
    font: normal normal 16px DomaineSansText-Regular;
    letter-spacing: 0.55px;
    font-weight: 500;
    color: #000;
    opacity: 1;
    margin: 0;
    padding: 0;
}

.link {
color: #b5b5b5 !important;
font: normal normal 14px DomaineSansText-Regular;
opacity: 1;
text-decoration: none;
margin-bottom: 1.2em;
font-size: medium;
}

.link:hover {
    color:#fff !important;
}

.txt-category{
    cursor: pointer;
    font: normal normal 14px DomaineSansText-Regular;
}

.meta {
    display: flex;
    float: left;
}
.keep-text {
font: normal normal 14px DomaineSansText-Regular;
letter-spacing: 0.53px;
color: #b5b5b5;
margin-top: 20px;
 width: 280px;
 font-size: medium;
}

.social-links {
    font: normal normal 28px/34px DomaineSansText-Regular;
    letter-spacing: 0px;
    color: #949494;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-items: baseline;
    justify-content: space-between; */
    margin-top: 5px;
    width: 200px;
}

.mobile-social-links {
    font: normal normal 28px/34px DomaineSansText-Regular;
    letter-spacing: 0px;
    color: #949494;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-items: baseline;
    justify-content: space-between; */
    margin-top: 5px;
    width: 200px;
}

.social-links-icons {
    display: flex;
    gap: 14px;
}

.social-media-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
}
.subscribe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 26px;
    width: 313px;
}

.txt-accept {
    color: #FFF;
    font-family: DomaineSansText-Regular;
    margin-bottom: 24px;
}

.txt-follow {
    color: #FFF;
    font-family: DomaineSansText-Regular;
    margin-bottom: 24px;
    font-size: 20px;
}


@media screen and (max-width: 600px) {
    .footer{
        /* padding: 26px 18px; */
        background: #000;
        /* width: 100vw; */
    }
    .contact, .div-contact {
        /* width:100px !important; */
        width: 100%;
    }
    .contact {
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        /* display:grid; */
        /* grid-template-columns: [col] 170px [col] 170px;*/
        /*grid-template-rows: [row] auto ; */
    }

    .footer-links{
        display: none;
    }
    .mobile-footer-links{
        display: block;
        padding: 26px 18px;
    }
    .footer-logo{
        margin-top: -11px;
    }

    .help {
        display: none;
    }

    .mobile-help {
        display: block;
        /* width: 320px; */
        width: 100%;
        margin-top: 20px;
    }

    .help-links {
        width: 100%;
        /* display:flex;
        flex-direction: row;
        justify-content: space-between; */
        display:grid;
        grid-template-columns: [col] 180px [col] 180px;
        grid-template-rows: [row] auto ;

    }

    .ant-form-item {
        margin: 0;
    }

    .info {
        padding: 5px 10px;
    }

    .title {
        color: #fff;
        font-family: DomaineSansText-Regular;
        font-size: 16px;
        font-weight: 700;
    }
    .form-title {
        margin-bottom: 10px;
    }
    .txt-phone{
        color: #fff;
        font-family: DomaineSansText-Regular;
        font-size: 14px;
    }
    .txt-address{
        color: #b5b5b5;
        font: normal bold 9px/20px DomaineSansText-Regular;
        width: 160px;
    }

    .payment-cards-container{
        display: none;
    }
    .mobile-payment-cards-container{
        display: inline-block;
        /* margin-top: 24px; */
        padding: 0px 18px;
    }

    .fText {
        font-weight: 600;
        color: #000;
        font: normal bold 9px/12px DomaineSansText-Regular;
        margin: 0;
        padding: 0;
    }

    .link {
        cursor: pointer;
        color: #b5b5b5;
        font-family: DomaineSansText-Regular;
        font-size: 14px;
    }
    
     .txt-category{
        cursor: pointer;
        color: #b5b5b5;
        font: normal bold 9px/12px DomaineSansText-Regular;
    }
    
    .keep-text{
        font-family: DomaineSansText-Regular;
        margin-top: 10px;
        width: 180px;
        font-size: 12px;
    }
    .txt-follow{
        color: #fff;
        font: normal 700 14px/18px DomaineSansText-Regular;
        margin-bottom: 18px;
    }

    .social-links{
        width:138px;
        display: none;
    }

    .mobile-social-links{
        width:138px;
        display: flex;
        padding: 0px 18px;
        margin-bottom: 20px;
    }

    .social-media-icon{
        /* width: 24px;
        height: 18px; */
    }

    .txt-accept{
        color: #fff;
        font-family: DomaineSansText-Regular;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 18px;
    }

    .payment{
        width:230px;
        margin-bottom: 20px;
    }

    .payment-icon{
        /* width: 20px; */
    }
    .paystack-icon {
        width: 24px;
    }

    .input-subscribe{
        color: #fff;
        font-family: DomaineSansText-Regular;
        height:36px !important;
        width:110px !important;
        font-size: 11px;
    }
    .input-subscribe::placeholder {
        color: #B7B7B7;
        font-size: 10px;
    }
    .btn-subscribe{
        border-color: #32353C;
        font-family: DomaineSansText-Regular;
        font-size: 11px;
        height:36px !important;
        width:60px;
    }
    .ant-btn{
        padding: 0;
    }
    .subscribe{
        margin-bottom: 0;
        /* width: 400px */
        width: 170px;
    }
    .fText-span {
        display: block;
    }
}